* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  /* background: linear-gradient(360deg, #EDEDED 0%, #FFFFFF 100%); */
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  height: 100vh;
}

h1 {
  color: #AAAAA9;
  font-weight: regular;
  font-size: 32px;
}

h2 {
  color: #AAAAA9;
  font-weight: regular;
  font-size: 24px;
}

h3 {
  color: #DD042B;
}

h4 {
  opacity: .6;
  color: #8C8C8C;
}

h4.dev {
  font-size: 14px;
  margin-top: 18px;
}

@media screen and (max-width: 768px) {
  
  h1 {
    color: #AAAAA9;
    font-weight: regular;
    font-size: 20px;
  }
  
  h2 {
    color: #AAAAA9;
    font-weight: regular;
    font-size: 17px;
  }
  
  h3 {
    color: #DD042B;
    font-size: 16px;
  }
  
  h4 {
    opacity: .6;
    color: #8C8C8C;
    font-size: 14px;
  }

  h4.dev {
    font-size: 12px;
    text-align: left;
  }
}