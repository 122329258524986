.styledBackgorund {
    background: linear-gradient(360deg, #EDEDED 0%, #FFFFFF 100%);
}

main {
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    height: 100vh;
    /* background: linear-gradient(360deg, #EDEDED 0%, #FFFFFF 100%); */
}

.img {
    margin: 30px auto;
    width: 400px;
}

.img img {
    max-width: 100%;
}

.wraper {
    margin-top: 100px;
}

a {
    text-decoration: none;
    outline: none;
    color: white;
    font-weight: 600;
}

.ButtonsDesk {
    padding: 30px;
}

.Button {
    width: 350px;
    background: #DC002D;
    margin: 20px auto;
    padding: 20px 30px;
    border-radius: 0.4rem;
    cursor: pointer;
}

.Button:hover {
    opacity: .6;
    transition: .3s all ease;
}

.directing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.footer {
    text-align: left;
    position: absolute;
    bottom: 30px;
}

.mobile {
    display: none;
}

.background_click {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    transition: .3s all ease-in-out;
}

.background_click.active {
    opacity: .4;
    display: initial;
}

.deskfooter {
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 30px;
}


@media screen and (max-width: 768px) {
    .img {
        margin: 0 auto;
        width: auto;
        margin: 15px 0;
    }

    .wraper {
        display: none;
    }


    .footer {
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .mobile {
        display: initial;
    }

    .desktop {
        display: none;
    }

    footer {
        padding: 30px;
    }

    .footer_M {
        text-align: center;
    }

    .background_click {
        display: initial;
    }
}

.floating-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    background-color: #DC002D;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: none;
  }
  
  .floating-button:hover {
    background-color: #DC002D;
  }


  @media screen and (max-width: 768px) {
    .floating-button {
      display: initial;
    }
  }




  /* MENU MOBILE TESTE */

  .mobile_menu {
    width: 18px;
    height: 3px;
    background: #fff;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    margin: 0 auto;
    position: relative;
  }

  .mobile_menu::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 3px;
    background: #fff;
    transition: all .5s ease-in-out;
    top: 0;
    left: 0;
    transform: rotate(90deg);
  }

.floating-button.active .mobile_menu::after {
    transform: rotate(68deg) ;
}

.floating-button.active .mobile_menu{
    transform: rotate(235deg);
}